import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { AutoAwesome } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import dataHubTextDev from '../../data-hub-text-dev.svg';
import dataHubTextProd from '../../data-hub-text-prod.svg';
import { ReactComponent as GoogleIcon } from '../../google/btn_google_light_normal_ios.svg';
import MagicLink from './MagicLink';

function AppWithAuth() {
  const { route, toSignUp, toResetPassword, toSignIn } = useAuthenticator(
    (context) => [context.user, context.route]
  );
  const location = useLocation();
  const params = useParams();
  const [switched, setSwitched] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [isLoggingIn, setLoggingIn] = useState(false);

  const [useMagicLink, setUseMagicLink] = useState(false);
  // route can be: 'authenticated' | 'confirmResetPassword' | 'confirmSignIn' | 'confirmSignUp' | 'confirmVerifyUser' | 'forceNewPassword' | 'idle' | 'resetPassword' | 'setup' | 'signOut' | 'setupTOTP' | 'signIn' | 'signUp' | 'transition' | 'verifyUser';
  useEffect(() => {
    if (route === 'signUp' && location.pathname !== '/auth/sign-up') {
      navigate(
        { pathname: '/auth/sign-up', search: location.search },
        {
          state: location.state,
        }
      );
      setSwitched(true);
    }
    if (route === 'signIn' && location.pathname !== '/auth/sign-in') {
      navigate(
        { pathname: '/auth/sign-in', search: location.search },
        {
          state,
        }
      );
      setSwitched(true);
    }
    if (
      route === 'resetPassword' &&
      location.pathname !== '/auth/reset-password'
    ) {
      navigate(
        {
          pathname: '/auth/reset-password',
          search: location.search,
        },
        {
          state,
        }
      );
      setSwitched(true);
    }
    if (
      route === 'confirmResetPassword' &&
      location.pathname !== '/auth/confirm-reset-password'
    ) {
      navigate(
        {
          pathname: '/auth/confirm-reset-password',
          search: location.search,
        },
        {
          state,
        }
      );
      setSwitched(true);
    }
  }, [route, location, navigate]);

  useEffect(() => {
    if (route !== 'idle' && route !== 'setup') {
      if (!switched) {
        switch (true) {
          case location.pathname.includes('/auth/sign-up'):
            if (route !== 'signUp') {
              toSignUp();
            }
            break;
          case location.pathname.includes('/auth/sign-in'):
            if (route !== 'signIn') {
              toSignIn();
            }
            break;
          case location.pathname.includes('/auth/reset-password'):
            if (route !== 'resetPassword') {
              toResetPassword();
            }
            break;
          case location.pathname.includes('/auth/confirm-reset-password/'):
            break;
          case location.pathname.includes('/auth/confirm-new-password/'):
            break;
          default:
            break;
        }
        setSwitched(true);
      }
    }
  }, [route, location, toSignUp, switched, toResetPassword, toSignIn, params]);
  async function logInIfPossible() {
    const environment = searchParams.get('environment');
    async function createNewAccountAfas() {
      await Auth.signUp({
        username: `AFAS_${environment}@gladior.ats`,
        password: `${Math.random().toString(36)}00000000000000000`.slice(2, 10),
        autoSignIn: {
          enabled: false,
        },
        attributes: {
          name: `AFAS_${environment}`,
          'custom:type': 'AFAS',
          'custom:environment': environment,
        },
      });
    }

    async function singInMagicLink() {
      try {
        enqueueSnackbar({
          message: 'Loging in with token...',
          variant: 'info',
        });
        const email = searchParams.get('user');
        const magicToken = searchParams.get('magicToken');
        searchParams.delete('user');
        searchParams.delete('magicToken');

        const user = await Auth.signIn({
          username: email,
        });
        await Auth.sendCustomChallengeAnswer(user, magicToken);
        await Auth.currentAuthenticatedUser();
        window.location.reload();
      } catch (error) {
        enqueueSnackbar({
          message: 'Login token invalid',
          variant: 'error',
        });
      }
    }

    async function signInAfas() {
      const email = `AFAS_${environment}@gladior.ats`;

      const user = await Auth.signIn({
        username: email,
      });

      await Auth.sendCustomChallengeAnswer(user, searchParams.get('afasToken'));
    }

    async function loginAfas() {
      localStorage.setItem(
        'redirectFromLogout',
        `${window.location.pathname}/${window.location.search}`
      );
      await Auth.signOut();
      localStorage.removeItem('redirectFromLogout');
      try {
        await signInAfas();
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log(error);
        await createNewAccountAfas();
        await signInAfas();
        await Auth.currentAuthenticatedUser();
      }
      setLoggingIn(false);

      searchParams.delete('afasToken');
      searchParams.delete('environment');
      navigate(
        {
          pathname: '/auth/sign-in',
          search: searchParams.toString(),
        },
        {
          state,
        }
      );
      window.location.reload();
    }
    if (searchParams.has('afasToken') && searchParams.has('environment')) {
      try {
        setLoggingIn(true);
        await loginAfas();
      } catch (error) {
        setLoggingIn(false);
      }
    }
    if (searchParams.has('magicToken') && searchParams.has('user')) {
      try {
        singInMagicLink();
      } catch (error) {
        // console.error(error);
      }
    }
  }

  useEffect(() => {
    logInIfPossible();
  }, []);

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          width: '100%',
        }}
      >
        <MagicLink
          handleclose={() => {
            setUseMagicLink(false);
          }}
          open={useMagicLink}
        ></MagicLink>

        <Container component="main" maxWidth="sm">
          <CssBaseline />
          {isLoggingIn ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <CircularProgress color="secondary"></CircularProgress>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              {route !== 'authenticated' && (
                <>
                  <Box sx={{ width: '396px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyItems: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt="Data Hub Logo"
                        src={
                          process.env.REACT_APP_USER_BRANCH === 'prod'
                            ? dataHubTextProd
                            : dataHubTextDev
                        }
                        style={{ height: '75px' }}
                      />
                    </Box>

                    <Box
                      sx={{
                        my: 2,
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        gap: 2,
                      }}
                    >
                      <Button
                        fullWidth
                        onClick={() => {
                          Auth.federatedSignIn({
                            provider: 'Google',
                            customState: JSON.stringify({
                              location,
                            }),
                          });
                        }}
                        startIcon={<GoogleIcon />}
                        sx={{ background: '#fff', textTransform: 'none' }}
                        variant="outlined"
                      >
                        Continue with Google
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        my: 2,
                      }}
                    >
                      <Divider>
                        <Typography color="text.secondary" variant="caption">
                          or
                        </Typography>
                      </Divider>
                    </Box>
                  </Box>

                  <Box>
                    <Authenticator
                      formFields={{
                        setupTOTP: {
                          QR: {
                            totpIssuer: 'Data Hub by Gladior',
                          },
                        },
                      }}
                      loginMechanisms={['email']}
                      signUpAttributes={['name', 'email']}
                      socialProviders={[]}
                    />
                    <Button
                      fullWidth
                      onClick={() => {
                        setUseMagicLink(true);
                      }}
                      startIcon={<AutoAwesome />}
                      sx={{
                        background: '#fff',
                        textTransform: 'none',
                        mt: 2,
                      }}
                      variant="outlined"
                    >
                      Passwordless sign in
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignContent: 'center',

                      width: '396px',
                    }}
                  >
                    <Typography
                      align="center"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                      variant="caption"
                    >
                      By signing up for Data Hub, you agree to the{' '}
                      <Link
                        color="secondary"
                        href="https://datahub.gladior.com/terms-of-service/"
                      >
                        Terms of Service
                      </Link>
                      . View our{' '}
                      <Link
                        color="secondary"
                        href="https://datahub.gladior.com/privacy-policy/"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Typography>

                    <Typography
                      align="center"
                      color="text.secondary"
                      variant="caption"
                    >
                      {'Copyright © '}
                      Data Hub powered by Gladior {new Date().getFullYear()}.
                    </Typography>
                  </Box>
                </>
              )}

              {route === 'authenticated' && (
                <>
                  <Navigate
                    replace
                    to={state?.redirectTo ? state?.redirectTo : `/`}
                  />
                </>
              )}
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
}

export default AppWithAuth;
