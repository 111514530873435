import { Auth } from 'aws-amplify';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

function RequireAuth({ children, redirectTo }) {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const location = useLocation();
  async function authenticate() {
    if (
      new URLSearchParams(location.search).get('afasToken') ||
      new URLSearchParams(location.search).get('magicToken')
    ) {
      localStorage.setItem(
        'redirectFromLogout',
        `${window.location.pathname}/${window.location.search}`
      );
      await Auth.signOut();
      localStorage.removeItem('redirectFromLogout');
    }
    if (localStorage.getItem('redirectFromLogout')) {
      localStorage.removeItem('redirectFromLogout');
      navigate(localStorage.getItem('redirectFromLogout'), {
        state: {
          redirectTo: location.pathname,
        },
      });
      return;
    }
    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsAuthenticated(true);
        setLoaded(true);
      })
      .catch(() => {
        navigate(
          {
            pathname: `/auth`,
            search: location.search,
          },
          {
            state: {
              redirectTo: location.pathname,
            },
          }
        );
      });
  }

  React.useEffect(() => {
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {})
      .catch(() => {
        if (isAuthenticated === true) setIsAuthenticated(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!loaded) return null;
  // eslint-disable-next-line no-unreachable
  return isAuthenticated ? (
    children
  ) : (
    <Navigate
      state={{
        redirectTo: location.pathname,
      }}
      to={`${redirectTo}${location.search}`}
    />
  );
}

export default RequireAuth;
